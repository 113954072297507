import React, { Component } from "react"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import SEO from "../components/base/seo"
import NotFound from "../components/marketing/NotFound"

export default class ErrorPage extends Component {
  render() {
    return (
      <div className="bg-white">
        <SEO title="Arus Oil - Not Found" />
        <Header page="404" />
        <NotFound />
        <Footer />
      </div>
    )
  }
}
